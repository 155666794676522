export const SECTIONS = [
  {
    title: "Prevention",
    reports: [
      {
        url: "https://cadth.ca/prevention-strategies-recurrent-tuberculosis-guidelines",
        title: "Prevention Strategies for Recurrent Tuberculosis: Guidelines",
        description: "(Summary of Abstracts, August 31, 2020)",
      },
      {
        url: "https://cadth.ca/prevention-tuberculosis-reactivation-clinical-utility-and-guidelines",
        title: "Prevention of Tuberculosis Reactivation: Clinical Utility and Guidelines",
        description: "(Summary of Abstracts, July 14, 2020)",
      },
      {
        url: "https://cadth.ca/contact-tracing-potential-exposure-tuberculosis-guidelines",
        title: "Contact Tracing for Potential Exposure to Tuberculosis: Guidelines",
        description: "(Summary of Abstracts, May 7, 2020)",
      },
      {
        url: "https://cadth.ca/isolation-measures-prevent-tuberculosis-transmission-clinical-effectiveness-and-guidelines",
        title:
          "Isolation Measures to Prevent Tuberculosis Transmission: Clinical Effectiveness and Guidelines",
        description: "(Summary of Abstracts, May 7, 2020)",
      },
      {
        url: "https://cadth.ca/portable-or-temporary-negative-pressure-rooms-tuberculosis-clinical-effectiveness-and-guidelines",
        title:
          "Portable or Temporary Negative Pressure Rooms for Tuberculosis: Clinical Effectiveness and Guidelines",
        description: "(Summary of Abstracts, April 24, 2020)",
      },
      {
        url: "https://cadth.ca/bacille-calmette-guerin-vaccination-review-clinical-effectiveness-and-guidelines",
        title:
          "Bacille Calmette-Guérin Vaccination: A Review of Clinical Effectiveness and Guidelines",
        description: "(Summary with Critical Appraisal, April 6, 2020)",
      },
      {
        url: "https://cadth.ca/vitamin-d-supplementation-prevention-tuberculosis-infection-clinical-effectiveness-and-guidelines",
        title:
          "Vitamin D Supplementation for the Prevention of Tuberculosis Infection: Clinical Effectiveness and Guidelines",
        description: "(Summary of Abstracts, March 31, 2020)",
      },
      {
        url: "https://cadth.ca/shared-smoking-devices-and-transmission-tuberculosis-clinical-evidence",
        title: "Shared Smoking Devices and the Transmission of Tuberculosis: Clinical Evidence",
        description: "(Summary of Abstracts, March 23, 2020)",
      },
      {
        url: "https://cadth.ca/tuberculosis-people-compromised-immunity-review-guidelines",
        title: "Tuberculosis in People with Compromised Immunity: A Review of Guidelines",
        description: "(Summary with Critical Appraisal, March 18, 2020)",
      },
      {
        url: "https://cadth.ca/prevention-tuberculosis-review-guidelines",
        title: "Prevention of Tuberculosis: A Review of Guidelines",
        description: "(Summary with Critical Appraisal, March 18, 2020)",
      },
      {
        url: "https://www.cadth.ca/prevention-tuberculosis-reactivation",
        title: "Prevention of Tuberculosis Reactivation",
        description: "(Health Technology Review, August 2021)",
      },
    ],
  },
  {
    title: "Identification",
    reports: [
      {
        url: "https://cadth.ca/tuberculosis-screening-people-chronic-conditions",
        title: "Tuberculosis Screening for People with Chronic Conditions",
        description: "(Summary with Critical Appraisal, May 13, 2021)",
      },
      {
        url: "https://cadth.ca/interferon-gamma-release-assay-identifying-latent-tuberculosis-infection-people-bacillus-calmette",
        title:
          "Interferon Gamma Release Assay for Identifying Latent Tuberculosis Infection in People With Bacillus Calmette-Guérin Vaccination",
        description: "(Summary with Critical Appraisal, May 5, 2021)",
      },
      {
        url: "https://cadth.ca/interferon-gamma-release-assay-identification-latent-tuberculosis-infection-rural-and-remote-0",
        title:
          "Interferon Gamma Release Assay for the Identification of Latent Tuberculosis Infection in Rural and Remote Settings",
        description: "(Summary with Critical Appraisal, April 9, 2021)",
      },
      {
        url: "https://cadth.ca/rapid-testing-diagnosis-pulmonary-tuberculosis-and-rifampicin-resistance-review-cost-effectiveness",
        title:
          "Rapid Testing for the Diagnosis of Pulmonary Tuberculosis and Rifampicin Resistance: A Review of Cost-Effectiveness ",
        description: "(Summary with Critical Appraisal, February 2021)",
      },
      {
        url: "https://cadth.ca/rapid-and-simultaneous-tuberculosis-and-antibiotic-susceptibility-testing-diagnosis-pulmonary-1",
        title:
          "Rapid and Simultaneous Tuberculosis and Antibiotic Susceptibility Testing for the Diagnosis of Pulmonary Tuberculosis and Rifampicin Resistance: Diagnostic Accuracy and Cost-Effectiveness",
        description: "(Summary with Critical Appraisal, December 2020)",
      },
      {
        url: "https://cadth.ca/latent-tuberculosis-infection-testing-people-compromised-immunity-prior-biologic-therapy-review",
        title:
          "Latent Tuberculosis Infection Testing in People with Compromised Immunity Prior to Biologic Therapy: A Review of Diagnostic Accuracy, Clinical Utility, and Guidelines ",
        description: "(Summary with Critical Appraisal, December 2020)",
      },
      {
        url: "https://cadth.ca/interferon-gamma-release-assay-identification-latent-tuberculosis-infection-people-previous-bacille",
        title:
          "Interferon Gamma Release Assay for the Identification of Latent Tuberculosis Infection in People with Previous Bacille Calmette-Guérin Vaccination: Clinical Utility, Cost-Effectiveness, and Guidelines",
        description: "(Summary of Guidelines, August 31, 2020)",
      },
      {
        url: "https://cadth.ca/tuberculosis-screening-people-chronic-conditions-guidelines",
        title: "Tuberculosis Screening for People with Chronic Conditions: Guidelines ",
        description: "(Summary of Abstracts, July 15, 2020)",
      },
      {
        url: "https://cadth.ca/latent-tuberculosis-infection-testing-people-compromised-immunity-prior-biologic-therapy-diagnostic",
        title:
          "Latent Tuberculosis Infection Testing in People with Compromised Immunity Prior to Biologic Therapy: Diagnostic Accuracy, Clinical Utility and Guidelines",
        description: "(Summary of Abstracts, June 26, 2020)",
      },
      {
        url: "https://cadth.ca/rapid-and-simultaneous-tuberculosis-and-antibiotic-susceptibility-testing-diagnosis-pulmonary",
        title:
          "Rapid and Simultaneous Tuberculosis and Antibiotic Susceptibility Testing for the Diagnosis of Pulmonary Tuberculosis and Rifampicin Resistance: Diagnostic Accuracy and Cost-Effectiveness",
        description: "(Summary of Abstracts, June 23, 2020)",
      },
      {
        url: "https://cadth.ca/screening-latent-tuberculosis-infection-post-secondary-institutions-clinical-utility-cost",
        title:
          "Screening for Latent Tuberculosis Infection in Post-Secondary Institutions: Clinical Utility, Cost-Effectiveness, and Guidelines",
        description: "(Summary of Abstracts, June 22, 2020)",
      },
      {
        url: "https://cadth.ca/occupational-screening-latent-tuberculosis-clinical-utility-cost-effectiveness-and-guidelines",
        title:
          "Occupational Screening for Latent Tuberculosis: Clinical Utility, Cost-Effectiveness, and Guidelines",
        description: "(Summary of Abstracts, June 22, 2020)",
      },
      {
        url: "https://cadth.ca/chest-x-rays-prior-placement-long-term-care-facilities-clinical-utility-and-guidelines-0",
        title:
          "Chest X-Rays Prior to Placement in Long-Term Care Facilities: Clinical Utility and Guidelines",
        description: "(Summary of Abstracts, June 8, 2020)",
      },
      {
        url: "https://cadth.ca/tuberculosis-people-compromised-immunity-review-guidelines",
        title: "Tuberculosis in People with Compromised Immunity: A Review of Guidelines",
        description: "(Summary with Critical Appraisal, March 18, 2020)",
      },
      {
        url: "https://cadth.ca/identification-tuberculosis-review-guidelines",
        title: "Identification of Tuberculosis: A Review of the Guidelines ",
        description: "(Summary with Critical Appraisal, March 18, 2020)",
      },
      {
        url: "https://cadth.ca/interventions-diagnosis-tuberculosis-clinical-effectiveness-and-guidelines",
        title:
          "Interventions for the Diagnosis of Tuberculosis: Clinical Effectiveness and Guidelines",
        description: "(Reference List, July 2, 2019)",
      },
      {
        url: "https://cadth.ca/interventions-used-screen-tuberculosis-clinical-effectiveness-and-guidelines",
        title:
          "Interventions Used to Screen for Tuberculosis: Clinical Effectiveness and Guidelines",
        description: "(Reference List, June 24, 2019)",
      },
      {
        url: "https://cadth.ca/occupational-screening-latent-tuberculosis-infection",
        title:
          "Occupational Screening for Latent Tuberculosis Infection",
        description: "(Health Technology Review, September 2021)",
      },
    ],
  },
  {
    title: "Treatment and Management",
    reports: [
      {
        url: "https://cadth.ca/visual-examination-frequency-people-taking-ethambutol-tuberculosis",
        title: "Visual Examination Frequency for People Taking Ethambutol for Tuberculosis",
        description: "(Summary with Critical Appraisal, May 25, 2021)",
      },
      {
        url: "https://cadth.ca/sites/default/files/pdf/htis/2021/RC1318%20TB%20Treatment%20Adherence%20Final.pdf",
        title: "Incentives and Support Programs to Improve Adherence to Tuberculosis Treatment ",
        description: "(Health Technology Review, February 2021)",
      },
      {
        url: "https://www.cadth.ca/sites/default/files/pdf/htis/2020/RC1312%20DOT%20for%20TB%20Final.pdf",
        title:
          "Direct Observational Therapy for the Treatment of Tuberculosis: A Review of Clinical Evidence and Guidelines ",
        description: "(Summary with Critical Appraisal, December 7, 2020)",
      },
      {
        url: "https://cadth.ca/support-programs-tuberculosis-treatment-clinical-utility-and-guidelines",
        title: "Support Programs for Tuberculosis Treatment: Clinical Utility and Guidelines",
        description: "(Summary of Abstracts, October 26, 2020)",
      },
      {
        url: "https://cadth.ca/management-discontinued-treatment-tuberculosis-guidelines",
        title: "Management of Discontinued Treatment for Tuberculosis: Guidelines",
        description: "(Summary of Abstracts, September 16, 2020)",
      },
      {
        url: "https://cadth.ca/shortened-drug-regimens-treatment-active-tuberculosis-clinical-effectiveness",
        title:
          "Shortened Drug Regimens for the Treatment of Active Tuberculosis: Clinical Effectiveness",
        description: "(Summary of Abstracts, August 31, 2020)",
      },
      {
        url: "https://cadth.ca/once-weekly-isoniazid-and-rifapentine-12-weeks-treatment-latent-tuberculosis-infection-clinical",
        title:
          "Once-weekly Isoniazid and Rifapentine for 12 Weeks for the Treatment of Latent Tuberculosis Infection: Clinical Utility and Cost- Effectiveness",
        description: "(Reference List, August 14, 2020)",
      },
      {
        url: "https://cadth.ca/treatment-latent-tuberculosis-infection-after-re-exposure-guidelines-0",
        title: "Treatment of Latent Tuberculosis Infection After Re-Exposure: Guidelines ",
        description: "(Summary of Abstracts, July 13, 2020)",
      },
      {
        url: "https://cadth.ca/therapeutic-substitution-drug-treatment-multi-drug-resistant-tuberculosis-guidelines",
        title:
          "Therapeutic Substitution of Drug Treatment for Multi-Drug Resistant Tuberculosis: Guidelines",
        description: "(Summary of Abstracts, July 13, 2020)",
      },
      {
        url: "https://cadth.ca/therapeutic-substitution-drugs-treatment-tuberculosis-guidelines",
        title: "Therapeutic Substitution of Drugs for the Treatment of Tuberculosis: Guidelines ",
        description: "(Summary of Abstracts, June 25, 2020)",
      },
      {
        url: "https://cadth.ca/visual-examination-frequency-people-taking-ethambutol-tuberculosis-guidelines",
        title:
          "Visual Examination Frequency for People Taking Ethambutol for Tuberculosis: Guidelines ",
        description: "(Summary of Abstracts, June 17, 2020)",
      },
      {
        url: "https://www.cadth.ca/direct-observational-therapy-treatment-tuberculosis-clinical-evidence-and-guidelines",
        title:
          "Direct Observational Therapy for the Treatment of Tuberculosis: Clinical Evidence and Guidelines",
        description: "(Summary of Abstracts, May 2020)",
      },
      {
        url: "https://cadth.ca/drug-resistant-tuberculosis-review-guidelines",
        title: "Drug-Resistant Tuberculosis: A Review of the Guidelines",
        description: "(Summary with Critical Appraisal, March 18, 2020)",
      },
      {
        url: "https://cadth.ca/treatment-tuberculosis-review-guidelines",
        title: "Treatment of Tuberculosis: A Review of Guidelines",
        description: "(Summary with Critical Appraisal, March 18, 2020)",
      },
      {
        url: "https://cadth.ca/tuberculosis-people-compromised-immunity-review-guidelines",
        title: "Tuberculosis in People with Compromised Immunity: A Review of Guidelines",
        description: "(Summary with Critical Appraisal, March 18, 2020)",
      },
      {
        url: "https://cadth.ca/interventions-treatment-or-management-tuberculosis-clinical-effectiveness-and-guidelines-0",
        title:
          "Interventions for the Treatment or Management of Tuberculosis: Clinical Effectiveness and Guidelines",
        description: "(Reference List, July 8, 2019)",
      }, {
        url: "https://www.cadth.ca/shortened-drug-regimens-treatment-active-tuberculosis",
        title:
          "Shortened Drug Regimens for the Treatment of Active Tuberculosis",
        description: "(Health Technology Review, August 2021)",
      },
    ],
  },
  {
    title: "Other",
    reports: [
      {
        url: "https://cadth.ca/tuberculosis-stigma-and-racism-colonialism-and-migration-rapid-qualitative-review",
        title:
          "Tuberculosis Stigma and Racism, Colonialism, and Migration: A Rapid Qualitative Review",
        description: "(Summary with Critical Appraisal, April 2021)",
      },
      {
        url: "https://www.cadth.ca/sites/default/files/hta-he/ht0033-tuberculosis-policy-insights-final.pdf",
        title: "Supports to Enhance Adherence to the Treatment of Latent Tuberculosis Infection",
        description: "(Policy Insight, December 2020)",
      },
    ],
  },
];