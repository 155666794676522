import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { isValidLink } from "../../lib/utils";
import { TGuideline } from "../../types";
import ExpandableCard from "../common/ExpandableCard";
import ExternalLink from "../common/ExternalLink";

const GUIDELINE_KEYS_ORDER = [
  "guidelineGroup",
  "countryOrRegion",
  "publicationYear",
  "qualityOfGuideline",
  "quality",
];

interface IGuidelines {
  guidelines?: TGuideline[];
}

const Guidelines = ({ guidelines }: IGuidelines) => {
  const { t } = useTranslation();

  return _.isEmpty(guidelines) ? null : (
    <ExpandableCard
      className="mt-5"
      title={t("labels.guidelines")}
      forwardRef={null}
      expandedByDefault={false}
    >
      {_.map(guidelines, (guideline, idx) => (
        <Guideline guideline={guideline} key={idx} />
      ))}
    </ExpandableCard>
  );
};

const Guideline = ({ guideline }: { guideline: TGuideline }) => {
  const { t } = useTranslation();
  return (
    <div className="guideline">
      <h1 className="font-semibold mb-2">{guideline.title}</h1>
      {_.map(GUIDELINE_KEYS_ORDER, (key) => {
        return _.isEmpty(_.get(guideline, key)) ? null : (
          <div key={key} className="flex flex-col md:flex-row text-2xs">
            <div className="font-semibold guideline-label">{t(`guideline.${key}`)}: </div>
            <div>{_.get(guideline, key)}</div>
          </div>
        );
      })}
      <div className="flex flex-col md:flex-row text-2xs">
        <div className="font-semibold guideline-label">{t("guideline.source")}: </div>
        <div>
          {isValidLink(guideline.sourceLink) ? (
            <ExternalLink url={guideline.sourceLink!}>{guideline.sourceText}</ExternalLink>
          ) : (
            guideline.sourceText
          )}
        </div>
      </div>
      <div className="guideline_separator" />
    </div>
  );
};

export default Guidelines;
