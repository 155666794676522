import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import ExpandableCard from "../common/ExpandableCard";

const AboutSection = ({ aboutSection }) => {
  return (
    <div className="mb-4">
      <ExpandableCard title={aboutSection.title}>
        <div dangerouslySetInnerHTML={{ __html: aboutSection.value }} />
      </ExpandableCard>
    </div>
  );
};

const About = () => {
  const { t } = useTranslation();

  const about = t("app.about", { returnObjects: true });

  return (
    <div>
      <div className="w-full max-w-screen-lg relative mx-auto">
        <div className="w-full p-2">
          {_.map(about, (aboutSection, key) => (
            <AboutSection key={key} aboutSection={aboutSection} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
