import _, { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { axiosInstance as axios } from "./api_utils";
import { params2filters } from "./helpers";
import { isDebugMode, isTestEnv } from "./utils";

export const handleApiError = (error) => {
  if (isDebugMode() || isTestEnv()) console.warn(error);
  // if (!isDebugMode()) sendReportToSentry(error);
};

export const useGetApi = (initialUrl, initialData = {}) => {
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios.get(url);
        setData(result.data);
      } catch (e) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url]);
  return [{ data, isLoading, isError }, setUrl];
};

export const usePostApi = (url, initialBody, initialData = {}) => {
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);

  const [body, setBody] = useState(initialBody);

  useEffect(() => {
    if (!isEmpty(body)) {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);
        try {
          const result = await axios.post(url, body);
          setData(result.data);
        } catch (e) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [url, body]);
  return [{ data, isLoading, isError }, setBody];
};

export const useRecommendationsFetch = (apiPrefix) => {
  const location = useLocation();
  const history = useHistory();
  const currentParams = new URLSearchParams(location.search);
  const [currentFilters, setCurrentFilters] = useState(params2filters(currentParams));

  const [currentIntentSelected, setCurrentIntentSelected] = useState(
    currentParams.get("intent") || "all"
  );

  const [{ data, isLoading, isError }, setUrl] = useGetApi(
    `/${apiPrefix}?${currentParams.toString()}`
  );

  const onChangeIntentSelected = useCallback(
    (intent) => () => {
      const newIntent = intent === currentIntentSelected ? "all" : intent;
      intent === "all" || _.isEmpty("intent")
        ? currentParams.delete("intent")
        : currentParams.set("intent", intent);
      setCurrentIntentSelected(newIntent);
      const newUrl = `/${apiPrefix}?${currentParams.toString()}`;
      history.push(`?${currentParams.toString()}`);
      setUrl(newUrl);
    },
    [currentIntentSelected, apiPrefix, currentParams, history, setUrl]
  );

  const onChangeFilter = (key, value) => {
    value === "any" || _.isEmpty(value) ? currentParams.delete(key) : currentParams.set(key, value);
    const newUrl = `/${apiPrefix}?${currentParams.toString()}`;
    history.push(`?${currentParams.toString()}`);
    setCurrentFilters(params2filters(currentParams));
    setUrl(newUrl);
  };

  const onClearFilters = useCallback(() => {
    const link =
      apiPrefix === "grid"
        ? "evidence-map"
        : apiPrefix === "key-messages"
        ? "evidence-reports"
        : apiPrefix;
    setUrl(`/${apiPrefix}?`);
    history.push(`/${link}?`);
    setCurrentFilters({});
  }, [setUrl, history, apiPrefix]);

  return {
    currentFilters,
    currentIntentSelected,
    data,
    isError,
    isLoading,
    onChangeFilter,
    onChangeIntentSelected,
    onClearFilters,
  };
};
