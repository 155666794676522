import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecommendationsFetch } from "../../lib/api";
import { useSearchQuery } from "../../lib/custom_hooks";
import { generateColorMap } from "../../lib/helpers";
import Sidebar from "../Sidebar";
import Search from "../common/Search";
import RecommendationsMapTable from "../recommendationMap/RecommendationsMapTable";

// TODO use gradient array
const gradientStart = "#99CE86";
const gradientMiddle = "#F0C343";
const gradientEnd = "#FF5D73";

const GridHeader = ({ onChangeFilter }) => {
  const initialSearchQuery = useSearchQuery();

  return (
    <div className="grid-page-header w-full bg-white mb-4">
      <div className="flex flex-row">
        <Search
          className="inline-block ml-3 mr-6 mt-1 mb-2"
          searchQuery={initialSearchQuery}
          onSearch={(query) => onChangeFilter("searchQuery", query)}
        />
      </div>
    </div>
  );
};

const Grid = ({
  isLoading,
  currentIntentSelected,
  onChangeIntentSelected,
  data,
  onChangeFilter,
  currentFilters,
  onClearFilters,
}) => {
  const [heatMap, setHeatMap] = useState(false);

  const colorsCount = data.maxCount || 10;

  // TODO use gradient array
  const colorMap = useMemo(
    () => generateColorMap(gradientStart, gradientMiddle, gradientEnd, colorsCount),
    [colorsCount]
  );

  return (
    <>
      <GridHeader onChangeFilter={onChangeFilter} />
      <RecommendationsMapTable
        currentIntentSelected={currentIntentSelected}
        onChangeIntentSelected={onChangeIntentSelected}
        colorMap={colorMap}
        isLoading={isLoading}
        displayMode={heatMap ? "heatMap" : "normal"}
        grid={data}
      />
      <Sidebar
        colorsCount={colorsCount}
        currentFilters={currentFilters}
        expandable
        filters={data.filterValues}
        heatMap={heatMap}
        isLoading={isLoading}
        gridSidebar
        onChangeFilter={onChangeFilter}
        onClearFilters={onClearFilters}
        setHeatMap={setHeatMap}
      />
    </>
  );
};

const GridPage = () => {
  const { t } = useTranslation();

  const {
    currentFilters,
    currentIntentSelected,
    data,
    isError,
    isLoading,
    onChangeFilter,
    onChangeIntentSelected,
    onClearFilters,
  } = useRecommendationsFetch("grid");

  if (_.isEmpty(data) && !isLoading) return null;

  return (
    <div className="bg-gray-100 overflow-hidden page-content relative font-roboto">
      {isError && <div>{t("errors.fetching_failed")}</div>}
      <Grid
        currentFilters={currentFilters}
        currentIntentSelected={currentIntentSelected}
        data={data}
        isLoading={isLoading}
        onChangeFilter={onChangeFilter}
        onChangeIntentSelected={onChangeIntentSelected}
        onClearFilters={onClearFilters}
      />
    </div>
  );
};

export default GridPage;
