import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ExpandableCard from "./ExpandableCard";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import QuestionData from "./QuestionData";
import { questionsHasCommonStrengthAndCertainty } from "../../lib/helpers";

const QuestionsInfo = ({ question, intervention, population, evidenceTableUrl }) => {
  const { t } = useTranslation();

  const commonStrengthAndCertainty = useMemo(
    () => questionsHasCommonStrengthAndCertainty(question),
    [question]
  );

  const questionsData = useMemo(() => {
    return (
      <QuestionsData
        commonStrengthAndCertainty={commonStrengthAndCertainty}
        interventions={intervention ?? []}
        populations={population ?? []}
        questions={question}
        evidenceTableUrl={evidenceTableUrl}
      />
    );
  }, [commonStrengthAndCertainty, question, intervention, population, evidenceTableUrl]);

  return !commonStrengthAndCertainty ? (
    <ExpandableCard title={t("labels.comparisons")} className="mb-5">
      {questionsData}
    </ExpandableCard>
  ) : (
    questionsData
  );
};

QuestionsInfo.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  intervention: PropTypes.arrayOf(interventionShape).isRequired,
  population: PropTypes.arrayOf(populationShape).isRequired,
  question: PropTypes.arrayOf(questionShape).isRequired,
  evidenceTableUrl: PropTypes.string,
};

QuestionsInfo.defaultProps = {
  commonStrengthAndCertainty: true,
  evidenceTableUrl: null,
};

const QuestionsData = ({
  commonStrengthAndCertainty,
  questions,
  interventions,
  populations,
  evidenceTableUrl,
}) => {
  return (
    <div>
      {_.map(questions, (question) => (
        <QuestionData
          commonStrengthAndCertainty={commonStrengthAndCertainty}
          interventions={interventions}
          key={question["@id"]}
          populations={populations}
          question={question}
          evidenceTableUrl={evidenceTableUrl}
        />
      ))}
    </div>
  );
};

QuestionsData.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  interventions: PropTypes.arrayOf(interventionShape).isRequired,
  populations: PropTypes.arrayOf(populationShape).isRequired,
  questions: PropTypes.arrayOf(questionShape).isRequired,
  evidenceTableUrl: PropTypes.string,
};

QuestionsData.defaultProps = {
  commonStrengthAndCertainty: true,
  evidenceTableUrl: null,
  loveLink: null,
  emlLinks: [],
};

export default QuestionsInfo;
