import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import RecommendationSnippet from "../recommendation/RecommendationSnippet";
import ReviewCategory from "../recommendation/ReviewCategory";

const RecommendationsComparison = ({ recommendationsToCompare }) => {
  return (
    <ul className="recommendations-to-compare">
      {_.map(recommendationsToCompare, (rec) => (
        <li key={rec["@id"]} className="flex flex-row pb-4 pt-2 px-2 mb-4">
          <div className="flex-grow mr-4">
            <Link
              to={`/evidence-report/${rec["@id"]}`}
              className="hover:bg-blue-1100"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="hover:bg-blue-1100">
                <ReviewCategory
                  guidelinesRelated={rec.guidelinesRelated}
                  noEvidenceFound={rec.noEvidenceFound}
                />
                <RecommendationSnippet text={rec.keyMessage} />
              </div>
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );
};

RecommendationsComparison.propTypes = {
  recommendationsToCompare: PropTypes.arrayOf(
    PropTypes.shape({
      "@id": PropTypes.string.isRequired,
      keyMessage: PropTypes.string.isRequired,
      linkToSource: PropTypes.string,
      sourceOfEvidence: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default RecommendationsComparison;
