import React, { useState } from "react";
import { DynamicHeightContainer } from "@ep/react-components";
import PropTypes from "prop-types";

const ExpandableCard = ({
  children,
  className,
  contentClassName,
  forwardRef,
  onTransitionEnd,
  title,
  tooltip,
  expandedByDefault,
}) => {
  const [expanded, setExpanded] = useState(expandedByDefault);
  return (
    <DynamicHeightContainer
      ref={forwardRef}
      className={className}
      onTransitionEnd={onTransitionEnd}
    >
      <div
        className={`
          cursor-pointer
          expand-toggle
          expandable-card
          hover:opacity-75
          px-3 
          py-2
          relative
          text-white
        ${expanded ? "expanded" : "collapsed"}`}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
        {tooltip && (
          <span
            className="info-tooltip-icon"
            data-event="click focus"
            data-event-off="blur mouseout"
            data-tip={tooltip}
          />
        )}
      </div>
      {expanded && (
        <div className={`expandable-card__content p-5 ${contentClassName}`}>
          <div>{children}</div>
          <div className="collapse-arrow">
            <button className="collapse-arrow__button" onClick={() => setExpanded(false)} />
          </div>
        </div>
      )}
    </DynamicHeightContainer>
  );
};

ExpandableCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  expandedByDefault: PropTypes.bool,
  contentClassName: PropTypes.string,
  onTransitionEnd: PropTypes.func,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

ExpandableCard.defaultProps = {
  className: "",
  contentClassName: "",
  onTransitionEnd: null,
  tooltip: null,
  expandedByDefault: true,
};

export default ExpandableCard;
