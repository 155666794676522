import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import GridCell from "../grid/GridCell";
import PopulationLabel from "./PopulationLabel";
import RMTGroup from "./RMTGroup";

const Populations = ({ rows, label }) => (
  <div className="populations">
    {map(rows, (population, pKey) => (
      <GridCell key={pKey} className="rmt-left-col-cell flex align-center">
        <PopulationLabel group={label} population={population} />
      </GridCell>
    ))}
  </div>
);

const PopulationGroups = ({ rowGroups }) => {
  return map(rowGroups, (group, gKey) => (
    <RMTGroup key={`left-col-${gKey}`}>
      <Populations rows={group.rows} label={group.key} />
    </RMTGroup>
  ));
};

const RMTLeftColumn = ({
  rowGroups,
  onChangeIntentSelected,
  currentIntentSelected,
  fixedColRef,
  interventionsPopulationRef,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-row items-center w-full h-full interventions overflow-hidden">
        <div
          className={`interventions-group interventions-group--all ${
            currentIntentSelected === "all" ? "current" : ""
          }`}
          onClick={onChangeIntentSelected("all")}
        >
          {t("groups.all")}
        </div>
      </div>

      <div
        className="text-white population-interventions overflow-hidden relative"
        ref={interventionsPopulationRef}
      >
        <div className="population-interventions--left relative">
          <div className="absolute top-0 right-0 t px-1">{t("labels.interventions")}</div>
          <div className="populations absolute bottom-0 text-left px-1">
            {t("labels.populations")}
          </div>
        </div>
        <div className="population-interventions--right"></div>
      </div>

      <div className="grid-fixed-col" ref={fixedColRef}>
        <PopulationGroups rowGroups={rowGroups} />
      </div>
    </>
  );
};

RMTLeftColumn.propTypes = {
  rowGroups: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          keyMessagesCount: PropTypes.number.isRequired,
        }).isRequired
      ).isRequired,
    })
  ),
};

export default RMTLeftColumn;
