import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

interface ILinkToSubpage {
  text: string;
  to: string;
  className?: string;
}
const LinkToSubpage = ({ text, to, className }: ILinkToSubpage) => (
  <Link
    className={`${
      className || ""
    } p-4 text-center items-center block w-full text-2xs shadow relative uppercase bg-cadth-700 text-white`}
    to={to}
  >
    {text}
  </Link>
);

LinkToSubpage.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkToSubpage.defaultProps = {
  className: "",
};

export default LinkToSubpage;
