import _ from "lodash";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { guidelineShape } from "../../lib/data_shapes";
import { isValidLink } from "../../lib/utils";
import CustomTable from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";

export const keysToSkip = [
  "@id",
  "displayId",
  "shortTitle",
  "title",
  "agreeScore",
  "intervention",
  "population",
  "guidelineNoId",
];
export const translatableKeys = ["version"];

export const getGuidelineRows = (t, guideline) => {
  const getValue = (t, key, value) => {
    if (_.includes(["publicationDate", "latestLiteratureSearchDate"], key)) {
      return value !== "not_reported"
        ? new Date(value).toISOString().slice(0, 10)
        : t(`recommendation.${value}`);
    }

    if (key === "country") {
      return value.join(", ");
    }

    if (key === "gradingEvidenceMethod") {
      if (_.includes(["notGraded", "grade"], value)) {
        return t(`recommendation.fieldValues.${key}.${value}`);
      } else {
        return value;
      }
    }

    if (_.includes(translatableKeys, key)) {
      return t(`recommendation.fieldValues.${key}.${value}`);
    }

    if (_.isArray(value)) {
      return value.map((v) => t(`recommendation.fieldValues.${key}.${v}`)).join(", ");
    } else if (
      _.isBoolean(value) ||
      value === "no" ||
      value === "false" ||
      value === "yes" ||
      value === "true"
    ) {
      const valueKey = value || value === "yes" || value === "true" ? "yes" : "no";
      return t(`recommendation.fieldValues.${key}.${valueKey}`);
    } else if (isValidLink(value)) {
      return `<a href="${value}" target="_blank" rel="noopener noreferer">${value}</a>`;
    } else if (value === "notReported") {
      return t(`recommendation.not_reported`);
    } else {
      return value.toString();
    }
  };

  return _.reduce(
    guideline,
    (acc, value, key) => {
      return keysToSkip.indexOf(key) !== -1 || _.isEmpty(value?.toString())
        ? acc
        : acc.concat({ label: t(`recommendation.${key}`), value: getValue(t, key, value) });
    },
    []
  );
};

const SourceOfRecommendation = ({ guideline, title }) => {
  const { t } = useTranslation();
  const rows = useMemo(() => {
    return getGuidelineRows(t, guideline);
  }, [t, guideline]);

  return guideline ? (
    <ExpandableCard
      className="mt-5"
      title={title || t("labels.sourceOfRecommendation")}
      tooltip={t("tooltips.sourceOfRecommendation")}
    >
      <h2 className="font-semibold m-3">{guideline.title}</h2>
      <CustomTable rows={rows} />
    </ExpandableCard>
  ) : null;
};

SourceOfRecommendation.propTypes = {
  guideline: guidelineShape,
  title: PropTypes.string,
};

SourceOfRecommendation.defaultProps = {
  title: null,
};

export default SourceOfRecommendation;
