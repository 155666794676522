import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const RMTPill = ({ displayMode, column, colorMap }) => {
  const { t } = useTranslation();
  const columnContent = column.count;

  const stringified = useMemo(() => JSON.stringify(column), [column]);

  const bgColor = useMemo(
    () =>
      displayMode === "heatMap"
        ? `#${colorMap[columnContent - 1]}`
        : "#007DB3",
    [displayMode, colorMap, columnContent]
  );

  const isEmptyContent = useMemo(() => !columnContent || columnContent === 0, [columnContent]);
  
  const pillStyles = useMemo(
    () => ({
      backgroundColor:
        columnContent === "not applicable"
          ? "#ADC2C5"
          : isEmptyContent
          ? "rgba(255, 255, 255, 0.5)"
          : bgColor,
    }),
    [bgColor, isEmptyContent, columnContent]
  );

  return (
    <div
      className={`rmt-pill ${columnContent > 0 ? "cursor-pointer" : "cursor-auto"}`}
      style={pillStyles}
      data-column={stringified}
      data-tip={t("labels.noOfKeyMessagesCellDescription")}
    >
      {!isEmptyContent && columnContent}
    </div>
  );
};

RMTPill.propTypes = {
  displayMode: PropTypes.oneOf(["normal", "heatMap"]).isRequired,
  column: PropTypes.shape({
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recommendations: PropTypes.array,
  }).isRequired,
  colorMap: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RMTPill;
