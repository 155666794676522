import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useSearchQuery } from "../../lib/custom_hooks";
import ExternalLink from "../common/ExternalLink";
import LinkToSubpage from "../common/LinkToSubpage";
import Search from "../common/Search";

const MainPageLinks = () => {
  const { t } = useTranslation("");
  return (
    <React.Fragment>
      <div className="w-full flex flex-row mt-6 max-w-screen-sm mx-auto">
        <LinkToSubpage
          className="tailwind-hidden md:block mr-4 hover:opacity-75"
          to="/evidence-map"
          text={t("labels.evidenceMap")}
        />
        <LinkToSubpage
          className="hover:opacity-75"
          to="/evidence-reports"
          text={t("labels.keyMessages")}
        />
      </div>
      <div className="w-full flex flex-col md:flex-row mt-4 max-w-screen-sm mx-auto">
        <LinkToSubpage
          className="md:block mr-4 hover:opacity-75"
          to="/external-resources"
          text={t("labels.externalResources")}
        />
        <LinkToSubpage
          className="hover:opacity-75 mt-4 md:mt-0"
          to="/knowledge-mobilization-tools"
          text={t("labels.knowledgeMobilizationTools")}
        />
      </div>
      <div className="w-full flex flex-col md:flex-row mt-4 max-w-screen-sm mx-auto">
        <LinkToSubpage
          className="hover:opacity-75 md:block mr-4"
          to="/list-of-cadth-reports"
          text={t("labels.listOfCadthReports")}
        />
        <ExternalLink
          className="hover:opacity-75 mt-4 md:mt-0 p-4 text-center items-center block w-full text-2xs shadow relative uppercase bg-cadth-700 text-white"
          url="https://tuberculosis.evidenceprime.com/"
        >
          {t("labels.whoEtbGuidelinesDatabase")}
        </ExternalLink>
      </div>
    </React.Fragment>
  );
};

const MainPage = () => {
  const { t } = useTranslation("");
  const history = useHistory();
  const initialSearchQuery = useSearchQuery();

  return (
    <div className="main-page-container relative flex flex-col">
      <div className="bg-cadth-500">
        <div className="flex flex-col text-center text-white mt-16 md:mt-32">
          <span className="font-bold uppercase text-3xl">{t("app.mainPageTitle")}</span>
          <span className="uppercase">{t("app.mainPageSubTitle")}</span>
        </div>
        <Search
          className="font-roboto mt-6 mb-12 block max-w-screen-sm mx-auto px-4 md:px-0"
          inputClassName="w-full"
          searchQuery={initialSearchQuery}
          onSearch={(query) =>
            history.push(
              `/evidence-reports?${new URLSearchParams(`searchQuery=${query}`).toString()}`
            )
          }
        />
      </div>
      <div className="main-page-container__top flex flex-col flex-grow w-full mb-20 px-4 md:px-0">
        <MainPageLinks />
        <div className="w-full my-12 max-w-screen-sm mx-auto font-light">
          <Trans i18nKey="app.mainPageDescription">
            <div className="mb-2">
              On this online platform, you can access{" "}
              <ExternalLink url="https://cadth.ca/">CADTH</ExternalLink> reviews of available
              research and guidelines, tools to support the uptake of this information and other
              resources to inform decisions in the care of tuberculosis (TB). The content provided
              on the platform is drawn from CADTH’s ongoing{" "}
              <Link className="text-cadth-500 hover:underline" to="/about">
                condition-level review
              </Link>{" "}
              of health technologies used for TB.
            </div>
            <div className="mb-2">
              The CADTH evidence you will find here covers topics that were identified as current
              priority areas of interest for Canadian stakeholders working in the field of TB. The
              topics chosen were based on input gathered during focus groups CADTH held with
              decision-makers in the field of TB, which included clinicians and policy-makers. To
              facilitate access to other TB evidence-based information, here, you will also find
              links to{" "}
              <Link className="text-cadth-500 hover:underline" to="/external-resources">
                external resources
              </Link>
              , including the{" "}
              <ExternalLink url="https://tuberculosis.evidenceprime.com/">
                WHO database of recommendations for TB prevention and care
              </ExternalLink>
            </div>
            <div className="mb-2">
              To make the key messages from CADTH’s research evidence and guideline reviews easier
              to access and use, the platform provides 2 different but complementary options:{" "}
              <ul className="list-disc ml-5 my-2">
                <li>
                  <Link className="text-cadth-500 hover:underline" to="/evidence-map">
                    Evidence Map
                  </Link>{" "}
                  - Use the map’s filter options to narrow down the key messages according to
                  specific categories of interest.
                </li>
                <li>
                  <Link className="text-cadth-500 hover:underline" to="/evidence-reports">
                    List of Key Messages
                  </Link>{" "}
                  - View the key messages in a list format, which you can also filter by category of
                  interest.
                </li>
              </ul>
              Both options allow you drill down to the source of each key message so you can get
              more information.
            </div>
            <div className="mb-2">
              CADTH’s condition-level review on TB aims to support decision-making by addressing
              current evidence needs and identifying evidence gaps and emerging information. This
              online platform aims to synthesize this information for easy use and reference.
            </div>
            <div className="mb-2">
              The platform will be updated as more evidence resources are completed (platform was
              last updated on October 2021).
            </div>{" "}
            <div className="mb-2">
              If you are interested in the evidence on a topic that this review has not covered yet,
              please send your submission for consideration to:{" "}
              <ExternalLink url="mailto:requests@cadth.ca">requests@cadth.ca</ExternalLink>. You can
              also notify us of any technical issues related to the platform through this email
              address.
            </div>
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
