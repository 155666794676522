import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { TIntent, TRecommendation, TReportType } from "../../types";
import Box from "../common/Box";
import Intents from "../common/Intents";
import ReviewCategory from "./ReviewCategory";

interface IRecommendationHeader {
  intents: TIntent[];
  recommendation: TRecommendation;
  reportType: TReportType;
  guidelinesRelated: boolean;
  noEvidenceFound: boolean;
}

const RecommendationHeader = ({
  intents,
  recommendation,
  reportType,
  guidelinesRelated,
  noEvidenceFound,
}: IRecommendationHeader) => {
  const { t } = useTranslation();

  if (!recommendation) return null;
  return (
    <div>
      <ReviewCategory guidelinesRelated={guidelinesRelated} noEvidenceFound={noEvidenceFound} />
      <Box className="bg-white">
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow p-5">
            <div className="font-semibold">
              {t("labels.reportType")}: {t(`evidenceReport.reportType.${reportType}`)}
            </div>
            <Intents intents={intents} />
            <div className="mr-6" dangerouslySetInnerHTML={{ __html: recommendation.value }} />
          </div>
        </div>
      </Box>
    </div>
  );
};

RecommendationHeader.propTypes = {
  recommendation: PropTypes.object.isRequired,
  intents: PropTypes.arrayOf(PropTypes.string),
};

RecommendationHeader.defaultProps = {
  intents: [],
};

export default RecommendationHeader;
