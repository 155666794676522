import React, { useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ExpandableCard from "../common/ExpandableCard";
import CustomTable from "../common/CustomTable";

const Source = ({ data }) => {
  const { t } = useTranslation();

  const rows = useMemo(() => {
    return _.reduce(
      data,
      (acc, value, key) => acc.concat({ label: t(`labels.${key}`), value }),
      []
    );
  }, [t, data]);

  return (
    <ExpandableCard className="mt-5" title={t("labels.source")}>
      <CustomTable className="flex-grow" rows={rows} />
    </ExpandableCard>
  );
};

Source.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Source;
