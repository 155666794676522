import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Box from "./Box";

const ComingSoon = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <Box className="bg-white p-12 border">
        <h1 className="text-center text-3xl">{t("labels.coming_soon")}</h1>
        <div className="text-center mt-6 text-cadth-100 hover:underline">
          <Link to="/">{t("labels.go_to_main_page")}</Link>
        </div>
      </Box>
    </div>
  );
};

export default ComingSoon;
