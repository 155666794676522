import _ from "lodash";
import React, { ReactElement } from "react";
import { SECTIONS } from "../../lib/listOfCadthReports";
import ExpandableCard from "../common/ExpandableCard";
import ExternalLink from "../common/ExternalLink";

const Report = ({
  report,
}: {
  report: { url: string; title: string; description: string };
}): ReactElement => {
  return (
    <div className="mb-5">
      <ExternalLink className="text-cadth-500 hover:opacity-75 hover:underline" url={report.url}>
        {report.title}
      </ExternalLink>
      <div className="text-gray-600 text-2xs">{report.description}</div>
    </div>
  );
};

export default function ListOfCadthReports(): ReactElement {
  return (
    <div>
      {_.map(SECTIONS, (section) => (
        <div className="mb-4">
          <ExpandableCard title={section.title} forwardRef={null}>
            <div>
              {_.map(section.reports, (report) => (
                <Report report={report} />
              ))}
            </div>
          </ExpandableCard>
        </div>
      ))}
    </div>
  );
}
