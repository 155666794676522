import { Switcher } from "@ep/react-components";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const HeatMapSwitcher = ({ heatMap, colorsCount, setHeatMap }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center justify-end mt-12 mr-3">
      {heatMap && (
        <div className="heat-map-legend rounded-full flex flex-row justify-between">
          <span>{1}</span>
          <span>{_.max([colorsCount, 3])}</span>
        </div>
      )}
      <Switcher
        buttonText={t("labels.heatMap")}
        checked={heatMap}
        onChange={() => setHeatMap(!heatMap)}
      />
    </div>
  );
};

HeatMapSwitcher.propTypes = {
  heatMap: PropTypes.bool.isRequired,
  colorsCount: PropTypes.number.isRequired,
  setHeatMap: PropTypes.func.isRequired,
};

export default HeatMapSwitcher;
