import React from "react";
import { Trans } from "react-i18next";
import ExternalLink from "../common/ExternalLink";
import Box from "../common/Box";

const ExternalResources = () => {
  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <Box className="bg-white p-12 border">
        <Trans i18nKey="app.nationalCollaboratingCentre">
          <p>
            <ExternalLink
              className="block text-cadth-500 hover:underline"
              url="https://nccid.ca/project-stream/tuberculosis/"
            >
              National Collaborating Centre for Infectious Diseases – Tuberculosis
            </ExternalLink>
          </p>
          <div>
            On this page, you will find information and resources by the National Collaborating
            Centre for Infectious Diseases, NCCID, and others working on tuberculosis (TB)
            elimination in Canada, including:
            <ul className="list-disc ml-6">
              <li>
                a{" "}
                <ExternalLink url="http://nccdh.ca/resources/entry/public-health-action-to-address-tuberculosis-in-homeless-shelter-settings-a">
                  curated list of resources for TB prevention in homeless shelters{" "}
                </ExternalLink>
              </li>
              <li>
                an{" "}
                <ExternalLink url="https://kumu.io/shivoanb/the-tb-journey-determinants-map-for-northern-first-nations-communities-apr-2021">
                  online educational tool on the TB determinants in northern First Nations
                  communities
                </ExternalLink>
              </li>
              <li>
                a{" "}
                <ExternalLink url="https://nccid.ca/publications/integrated-ltbi-care-for-refugees/">
                  promising practice case study on integrated care for latent TB infection at a
                  refugee health clinic.
                </ExternalLink>
              </li>
            </ul>
          </div>
        </Trans>
        <Trans i18nKey="app.canadianTuberculosisStandards">
          <p className="mt-8">
            <ExternalLink
              className="inline-block text-cadth-500 hover:underline"
              url="https://www.tandfonline.com/toc/ucts20/6/sup1"
            >
              Canadian Tuberculosis Standards - 8th Edition
            </ExternalLink>{" "}
            (Canadian Journal of Respiratory, Critical Care, and Sleep Medicine, Volume 6, Issue
            sup1 (2022))
          </p>
          <div>
            The 8th edition of the Canadian Tuberculosis Standards (the Standards) includes new
            information on diagnostics and treatments, building upon the 7 previous versions of the
            Standards, which were published in 1972 (with a pediatric supplement in 1974), 1981,
            1988, 1996, 2000, 2007 and 2013. The objective of this document remains to provide
            practical management information to public health and clinical professionals on all
            aspects of the pathogenesis, epidemiology, and management of TB in Canada.
          </div>
        </Trans>
        <Trans i18nKey="app.whoConsolidatedGuidelinesOnTuberculosis">
          <p className="mt-8">
            <ExternalLink
              className="inline-block text-cadth-500 hover:underline"
              url="https://www.who.int/publications/i/item/9789240046764"
            >
              WHO consolidated guidelines on tuberculosis Module 5: Management of tuberculosis in
              children and adolescents
            </ExternalLink>{" "}
            (World Health Organization Resource; March 2022)
          </p>
          <div>
            The updated guidelines on management of tuberculosis in children and adolescents include
            new recommendations that cover diagnostic approaches for TB, shorter treatment for
            children with non-severe drug-susceptible TB, a new option for the treatment of TB
            meningitis, the use of bedaquiline and delamanid in young children with multidrug- and
            rifampicin-resistant TB and decentralized and family-centred, integrated models of care
            for TB case detection and prevention in children and adolescents.
          </div>
        </Trans>
      </Box>
    </div>
  );
};

export default ExternalResources;
