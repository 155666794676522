import React from "react";
import ComingSoon from "../common/ComingSoon";

const Glossary = (): JSX.Element => {
  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <ComingSoon />
    </div>
  );
};

export default Glossary;
