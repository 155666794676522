import PropTypes from "prop-types";
import React from "react";

const RecommendationSnippet = ({ text }) => {
  const textNode = new DOMParser().parseFromString(text, "text/html").body.firstChild?.firstChild;
  return <div dangerouslySetInnerHTML={{ __html: textNode?.innerHTML }} />;
};

RecommendationSnippet.propTypes = {
  text: PropTypes.string.isRequired,
};

export default RecommendationSnippet;
