import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import RecommendationItem from "./RecommendationItem";

export const RecommendationsList = ({ recommendations }) => (
  <ul className="recommendation-list ml-1 md:ml-1 mt-6 mr-1 md:mr-2">
    <li>
      <div className="bg-transparent pb-1">
        <div className="mb-1">
          {map(recommendations, (recommendation, idx) => (
            <RecommendationItem
              key={`${idx}-${recommendation["@id"]}`}
              recommendation={recommendation}
            />
          ))}
        </div>
      </div>
    </li>
  </ul>
);

const RecommendationGroupsList = ({ recommendations }) => {
  const { t } = useTranslation();
  return (
    <div className="mx-0 md:mx-2 pt-0">
      <div className="recommendation-list-container max-w-screen-lg mt-0 pt-0">
        {recommendations ? (
          <RecommendationsList recommendations={recommendations} />
        ) : (
          <div className="bg-white shadow mb-1 p-5 lg:mr-3 text-blue-800">
            {t("errors.no_results")}
          </div>
        )}
      </div>
    </div>
  );
};

RecommendationGroupsList.propTypes = {
  recommendationGroup: PropTypes.shape({
    name: PropTypes.oneOf(["all", "diagnostic", "preventive", "screening", "therapeutic"])
      .isRequired,
    count: PropTypes.number.isRequired,
    tbTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        recommendations: PropTypes.array.isRequired,
      })
    ),
  }),
};

export default RecommendationGroupsList;
