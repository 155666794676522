import _ from "lodash";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ExpandableCard from "../common/ExpandableCard";
import CustomTable from "../common/CustomTable";
import { useTranslation } from "react-i18next";

const POSSIBLE_OPTIONS = ["yes", "probably_yes", "probably_no", "no"];

export const ConclusionsRow = ({ section }) => {
  const { t } = useTranslation();

  return (
    <div className="conclusions-row">
      {!_.isEmpty(section.selectedOption) && _.includes(POSSIBLE_OPTIONS, section.selectedOption) && (
        <div className="conclusions-row__marker">
          <span className="font-semibold">{t(`labels.${section.selectedOption}`)}.</span>
        </div>
      )}
      {!_.isEmpty(section.content) && (
        <div
          className="conclusions-row__content"
          dangerouslySetInnerHTML={{ __html: section.content }}
        />
      )}
    </div>
  );
};

ConclusionsRow.propTypes = {
  section: PropTypes.shape({
    content: PropTypes.string,
  }).isRequired,
};

const Conclusions = ({ title, conclusions }) => {
  const { t } = useTranslation();

  const { sectionsOrder, sections } = conclusions;

  const rows = useMemo(
    () =>
      _.reduce(
        sectionsOrder,
        (acc, sectionName) => {
          return !_.isEmpty(sections[sectionName])
            ? acc.concat({
                label: t(`sections.${sectionName}`),
                value: <ConclusionsRow section={sections[sectionName]} />,
              })
            : acc;
        },
        []
      ),
    [t, sections, sectionsOrder]
  );

  const hasAnyValue = _.some(sections, (section) => !_.isEmpty(section));
  if (!hasAnyValue) return null;

  return (
    <ExpandableCard className="mt-5" title={title || t("labels.additionalInformation")}>
      <CustomTable className="flex-grow" rows={rows} />
    </ExpandableCard>
  );
};

Conclusions.propTypes = {
  title: PropTypes.string,
  conclusions: PropTypes.shape({
    sections: PropTypes.objectOf(PropTypes.shape({ content: PropTypes.string })).isRequired,
    sectionsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

Conclusions.defaultProps = {
  title: null,
  conclusions: {
    sections: {},
    sectionsOrder: [],
  },
};

export default Conclusions;
