import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import GridRow from "../grid/GridRow";
import RMTGroup from "./RMTGroup";
import RMTRow from "./RMTRow";

const RMTBody = ({ headerWidth, rowGroups, colorMap, displayMode, onClick }) => {
  return (
    <div onClick={onClick} style={{ width: headerWidth, minHeight: "10px" }}>
      {map(rowGroups, (group, gKey) => (
        <RMTGroup key={`body-${gKey}`}>
          {map(group.rows, (row, idx) => (
            <GridRow
              key={`row.name-${idx}`}
              className="rmt-body-row flex-row"
              style={{ width: headerWidth }}
            >
              <RMTRow columns={row.columns} colorMap={colorMap} displayMode={displayMode} />
            </GridRow>
          ))}
        </RMTGroup>
      ))}
    </div>
  );
};

RMTBody.propTypes = {
  displayMode: PropTypes.oneOf(["normal", "heatMap"]).isRequired,
  colorMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  headerWidth: PropTypes.string.isRequired,
};

export default RMTBody;
