import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CADTHRapidResponseReportLink } from "../../lib/constants";
import RecommendationSnippet from "./RecommendationSnippet";
import ReviewCategory from "./ReviewCategory";

export const LINKED_REPORTS = [
  "rapidResponseReferenceList",
  "rapidResponseSummaryOfAbstract",
  "rapidResponseSummaryWithCriticalAppraisal",
];

interface IReportType {
  reportType: string;
}

export const ReportType = ({ reportType }: IReportType) => {
  const { t } = useTranslation();
  return !_.isEmpty(reportType) ? (
    <div>
      {_.includes(LINKED_REPORTS, reportType) ? (
        <a
          className="text-cadth-100 hover:underline"
          href={CADTHRapidResponseReportLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`evidenceReport.reportType.${reportType}`)}
        </a>
      ) : (
        <div>{t(`evidenceReport.reportType.${reportType}`)}</div>
      )}
    </div>
  ) : null;
};

interface IRecommendationItem {
  recommendation: {
    "@id": string;
    sourceOfEvidence: string;
    reportType: string;
    keyMessage: string;
    guidelinesRelated: boolean;
    noEvidenceFound?: boolean;
  };
}

const RecommendationItem = ({ recommendation }: IRecommendationItem) => {
  const {
    "@id": id,
    keyMessage: text,
    guidelinesRelated,
    noEvidenceFound,
  } = recommendation;

  return (
    <div className="recommendation-item bg-white shadow mb-3 last:mb-0 hover:bg-blue-1100 hover:shadow text-sm">
      {/* TODO: components below commented out due to changes requested in T4249,
          and may be brought back soon */}
      {/* {sourceOfEvidence && (
        <Link className="text-gray-800 block px-4 pt-4 font-semibold" to={`/evidence-report/${id}`}>
          {sourceOfEvidence}
        </Link>
      )} */}
      {/* <div className="px-4 py-2">
        <ReportType reportType={reportType} />
      </div> */}
      <Link className="text-gray-800 block px-4 py-4" to={`/evidence-report/${id}`}>
        <ReviewCategory guidelinesRelated={guidelinesRelated} noEvidenceFound={noEvidenceFound} />
        <RecommendationSnippet text={text} />
      </Link>
    </div>
  );
};

export default RecommendationItem;
