import { includes } from "lodash";
import { useRef, useEffect } from "react";

export const isDebugMode = () => {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "jest";
};

export const isTestEnv = () => {
  // TODO change hostname to correct when test enviroment is prepared
  return window.location.hostname === "covid19.test.evidenceprime.com";
};

// TODO configure later
// export const sendReportToSentry = error => Raven.captureException(error);

export const isValidLink = (maybeUrl) => {
  try {
    const url = new URL(maybeUrl);
    return includes(["http:", "https:"], url.protocol);
  } catch (_ignored) {
    return false;
  }
};

export function useDidUpdateEffect(fn, depsList = []) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, [fn, ...depsList]);  // eslint-disable-line react-hooks/exhaustive-deps
}
