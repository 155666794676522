import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const ICONS = {};

const TYPES = ["regular", "good-practice-statement", "guideline"];
const SIZE = ["large", "small"];

const TypeHeader = ({ className, informal, type, size }) => {
  const { t } = useTranslation();

  return _.includes(TYPES, type) ? (
    <div className={`flex items-center ${className}`}>
      <Icon informal={informal} recommendationType={type} size={size} />
      <Title
        title={t(`labels.${_.snakeCase(informal ? "informal" : type)}_recommendation`)}
        size={size}
      />
    </div>
  ) : null;
};

TypeHeader.propTypes = {
  className: PropTypes.string,
  informal: PropTypes.bool,
  type: PropTypes.oneOf(TYPES).isRequired,
  size: PropTypes.oneOf(SIZE),
};

TypeHeader.defaultProps = {
  className: "",
  size: "large",
  informal: false,
};

export const Title = ({ className, title, size }) => {
  return (
    <div
      className={`${
        size === "large" ? "text-xl font-gothic" : "text-xs font-semibold font-roboto"
      } ${className}`}
    >
      {title}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(SIZE),
  className: PropTypes.string,
};

Title.defaultProps = {
  className: "",
};

export const Icon = ({ informal, recommendationType, size }) => {
  const iconType = informal ? "additionalGuidance" : recommendationType;
  return !_.isEmpty(ICONS[_.camelCase(iconType)]) ? (
    <img
      src={ICONS[_.camelCase(iconType)]}
      className={size === "large" ? "w-8 mr-3" : "w-5  mr-2"}
      alt=""
    />
  ) : null;
};

Icon.propTypes = {
  informal: PropTypes.bool,
  recommendationType: PropTypes.oneOf(TYPES).isRequired,
  size: PropTypes.oneOf(SIZE),
};

Icon.defaultProps = {
  size: "large",
  informal: false,
};

export default TypeHeader;
