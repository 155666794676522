import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecommendationsFetch } from "../../lib/api";
import { useSearchQuery } from "../../lib/custom_hooks";
import Sidebar from "../Sidebar";
import FakeLoader from "../common/FakeLoader";
import Search from "../common/Search";
import RecommendationGroupsList from "../recommendation/RecommendationGroupsList";

const GROUPS = ["all", "prevention", "identification", "treatment", "management"];

const RecommendationsPage = () => {
  const initialSearchQuery = useSearchQuery();
  const { t } = useTranslation();
  const {
    currentFilters,
    data,
    isError,
    isLoading,
    onChangeFilter,
    onClearFilters,
    onChangeIntentSelected,
    currentIntentSelected,
  } = useRecommendationsFetch("key-messages");

  return (
    <div className="overflow-hidden relative  page-content">
      <div
        className="relative
          h-full w-full  flex flex-col-reverse sm:flex-row justify-end overflow-visible"
      >
        <div className="w-full">
          <div className="w-full bg-white shadow mb-1 relative" style={{ zIndex: 5 }}>
            <div className="flex flex-row items-center w-full max-w-screen-lg mx-auto">
              <div className="flex flex-grow">
                <Search
                  className="inline-block mt-8 mx-3 md:mt-1 mb-2"
                  searchQuery={initialSearchQuery}
                  onSearch={(query) => onChangeFilter("searchQuery", query)}
                />
              </div>
            </div>
            <div className="w-full max-w-screen-lg mx-auto tailwind-hidden md:block">
              <div className="mx-3">
                {_.map(GROUPS, (group) => {
                  const buttonClassName =
                    group === currentIntentSelected
                      ? "text-white bg-cadth-700"
                      : "text-black bg-white hover:text-white";
                  return (
                    <button
                      key={group}
                      className={`group-filter-btn py-2 hover:bg-cadth-300 ${buttonClassName}`}
                      onClick={onChangeIntentSelected(group)}
                    >
                      {t(`groups.${group}`)}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex-grow w-full max-w-screen-lg mx-auto">
            {isError && <div>{t("errors.fetching_failed")}</div>}
            <FakeLoader isLoading={isLoading} interval={75} />
            {!isLoading && <RecommendationGroupsList recommendations={data?.keyMessages} />}
          </div>
        </div>
        {isLoading || _.isEmpty(data) ? null : (
          <Sidebar
            isLoading={isLoading}
            filters={data.filterValues}
            currentFilters={currentFilters}
            onChangeFilter={onChangeFilter}
            onClearFilters={onClearFilters}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendationsPage;
