import React from "react";
import PropTypes from "prop-types";

const Box = ({ className, contentClassName, label, children, tooltip }) => {
  return (
    <div data-tip={tooltip} className={`rounded-lg ${className}`}>
      {label && <div className="text-blue-800 text-sm">{label}</div>}
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
};

Box.defaultProps = {
  className: "",
  contentClassName: "",
  tooltip: null,
  label: null,
};

export default Box;
