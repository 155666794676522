import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/CADTH-logo.svg";
import logoMobile from "../assets/CADTH-logo-mobile.svg";
import HamburgerMenu from "./common/HamburgerMenu";

const HeaderLink = ({ linkId, activeRoute }) => {
  const { t } = useTranslation();
  const navLinkClasses = useCallback(
    (link) => {
      return `top-nav-link text-white font-bold uppercase mr-4 lg:mr-6 py-1 ${
        linkId === "evidence-map" ? "tailwind-hidden md:inline-block" : "inline-block"
      } ${activeRoute === link ? "active" : null}`;
    },
    [activeRoute, linkId]
  );
  return (
    <>
      <Link className={navLinkClasses(linkId)} to={`/${linkId}`}>
        {t(`labels.${_.camelCase(linkId)}`)}
      </Link>
    </>
  );
};

const Header = () => {
  const location = useLocation();

  const activeRoute = useMemo(() => _.first(_.split(_.trimStart(location.pathname, "/"), "/")), [
    location.pathname,
  ]);

  return (
    <header
      className={`z-10 bg-cadth-500 relative pl-2 h-12 sm:h-15 flex flex-row items-center 
    ${activeRoute === "" ? "" : "shadow"}`}
    >
      <div className="logo flex-grow flex flex-row">
        {location.pathname !== "/" && <Link to="/" className="mobile-back-link block sm:hidden" />}
        <Link className="flex flex-row items-center" to="/">
          <img src={logo} className="tailwind-hidden sm:block" alt="logo" />
          <img src={logoMobile} className="pr-4 block sm:hidden" alt="logo" />
        </Link>
      </div>
      <div className="flex flex-row items-center">
        <nav className="mr-12">
          <HeaderLink linkId="evidence-map" activeRoute={activeRoute} />
          <HeaderLink linkId="evidence-reports" activeRoute={activeRoute} />
        </nav>
        <HamburgerMenu />
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
