import _ from "lodash";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import EpLogo from "../../assets/ep-logo.svg";
import GradeProLogo from "../../assets/gradepro-logo.svg";
import MacGradeLogo from "../../assets/macgrade-logo.svg";
import CochraneLogo from "../../assets/cochrane-logo.svg";
import {
  evidenceprimeLink,
  twitterLink,
  gradeProLink,
  macGradeLink,
  cochraneCanadaLink,
} from "../../lib/constants";
import { useHandleClickOutside } from "../../lib/custom_hooks";

const MenuItem = ({ label, onClick }) => {
  return (
    <button className="hamburger-link block font-semibold hover:opacity-50 mb-6" onClick={onClick}>
      <span>{label}</span>
    </button>
  );
};

const HamburgerMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const goTo = (page) => () => {
    history.push(page);
    setMenuOpen(false);
  };

  useHandleClickOutside(menuRef, () => setMenuOpen(false));

  return (
    <div ref={menuRef} className="h-6 relative ml-auto mt-1 self-start justify-end hamburger-menu">
      <button
        className={`open-menu block ${menuOpen ? "open" : "closed"}`}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      />

      {menuOpen && (
        <div className="menu-container">
          <div className="flex flex-col h-full">
            <div className="flex flex-col flex-grow">
              <div className="py-5 mx-5">
                <MenuItem onClick={goTo("/about")} label={t("labels.about")} />
                <MenuItem onClick={goTo("/glossary")} label={t("labels.glossary")} />
                <a
                  className="hamburger-link block font-semibold hover:opacity-50 mb-6"
                  href="https://cadth.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("labels.visitCadth")}
                </a>
              </div>
              {!_.isEmpty(twitterLink) && (
                <div className="py-5 mx-5">
                  <a
                    className="social-link twitter"
                    href={twitterLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="logo" />
                    {t("labels.visitOurTwitter")}
                  </a>
                </div>
              )}
              <div className="logos flex flex-col flex-grow justify-end">
                <a
                  className="block mb-12 flex justify-end mr-6"
                  href={macGradeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={MacGradeLogo} width={120} alt="MacGRADE Centre" />
                </a>
                <a
                  className="block mb-12 flex justify-end mr-6"
                  href={cochraneCanadaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={CochraneLogo} width={120} alt="Cochrane Canada" />
                </a>
                <a
                  className="block mb-12 flex justify-end mr-6"
                  href={evidenceprimeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={EpLogo} alt="EvidencePrime" />
                </a>
                <a
                  className="block mb-6 flex justify-end mr-6"
                  href={gradeProLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={GradeProLogo} alt="GRADEpro" />
                </a>
              </div>
            </div>

            <div className="text-center sm:text-left">
              <button className="close-menu-arrow" onClick={() => setMenuOpen(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HamburgerMenu.propTypes = {};

export default HamburgerMenu;
