import React, { ReactNode } from "react";
import PropTypes from "prop-types";

interface IExternalLink {
  children: ReactNode;
  url: string;
  className?: string;
}

const ExternalLink = ({ children, url, className }: IExternalLink) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className || "text-cadth-500 hover:underline"}
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ExternalLink.defaultProps = {
  className: "",
};

export default ExternalLink;
