import _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as GuidelineReviewIcon } from "../../assets/document.svg";
import { ReactComponent as EvidenceReviewIcon } from "../../assets/research.svg";

const REVIEW_CATEGORY_MAP = {
  guidelineReview: {
    icon: GuidelineReviewIcon,
    title: "labels.guidelineReview",
    evidenceNotFound: "labels.guidelineNotFound",
  },
  evidenceReview: {
    icon: EvidenceReviewIcon,
    title: "labels.evidenceReview",
    evidenceNotFound: "labels.evidenceNotFound",
  },
};

interface IReviewCategory {
  guidelinesRelated: boolean;
  noEvidenceFound?: boolean;
}

const ReviewCategory = ({ guidelinesRelated, noEvidenceFound }: IReviewCategory) => {
  const { t } = useTranslation();

  const reviewCategory = useMemo(
    () => (guidelinesRelated ? "guidelineReview" : "evidenceReview"),
    [guidelinesRelated]
  );
  const category = REVIEW_CATEGORY_MAP[reviewCategory];

  return (
    <div className="mb-2">
      <div className="flex flex-row items-center text-xs font-semibold">
        <category.icon
          className={`mr-2 review-category-icon ${_.kebabCase(reviewCategory)} ${
            noEvidenceFound ? "grayed" : ""
          }`}
        />
        {t(category.title)}
      </div>
      {noEvidenceFound && (
        <div className="text-2xs text-gray-600">{t(category.evidenceNotFound)}</div>
      )}
    </div>
  );
};

export default ReviewCategory;
