import { Modal as ModalBase } from "@ep/react-components";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useHandleClickOutside } from "../../lib/custom_hooks";

const Modal = ({ isOpen, children, onClose, closeButton, modalSize }) => {
  const contentRef = useRef(null);

  useHandleClickOutside(contentRef, onClose);

  return (
    <ModalBase isOpen={isOpen} modalSize={modalSize}>
      <div className="modal-content" ref={contentRef}>
        {closeButton && onClose && (
          <div>
            <span className="close-modal" onClick={onClose} />
          </div>
        )}
        {children}
      </div>
    </ModalBase>
  );
};

Modal.propTypes = {
  closeButton: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  modalSize: PropTypes.string,
};

Modal.defaultProps = {
  closeButton: false,
  onClose: null,
  modalSize: "medium",
};

export default Modal;
