import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LargeSpinner from "./components/common/LargeSpinner";
import Header from "./components/Header";
import PageLayout from "./components/layouts/PageLayout";
import About from "./components/pages/About";
import ExternalResources from "./components/pages/ExternalResources";
import Glossary from "./components/pages/Glossary";
import Grid from "./components/pages/Grid";
import GuidelinePage from "./components/pages/GuidelinePage";
import KnowledgeMobilizationTools from "./components/pages/KnowledgeMobilizationTools";
import ListOfCadthReports from "./components/pages/ListOfCadthReports";
import MainPage from "./components/pages/MainPage";
import RecommendationPage from "./components/pages/RecommendationPage";
import RecommendationsPage from "./components/pages/RecommendationsPage";

export const AppContent = () => {
  const { t } = useTranslation("");

  return (
    <div className="font-roboto min-h-screen overflow-hidden w-full m-0 p-0 bg-gray-100">
      <div className="min-h-screen overflow-hidden m-0 p-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("app.title")}</title>
          <link rel="icon" type="image/png" href="favicon.png" sizes="512x512" />
        </Helmet>
        <Switch>
          <>
            <Header />
            <Route exact path="/">
              <MainPage />
            </Route>
            <Route exact path="/evidence-reports">
              <RecommendationsPage />
            </Route>
            <Route exact path="/evidence-map">
              <Grid />
            </Route>
            <Route exact path="/glossary">
              <PageLayout currentPage="glossary">
                <Glossary />
              </PageLayout>
            </Route>
            <Route exact path="/external-resources">
              <PageLayout currentPage="externalResources">
                <ExternalResources />
              </PageLayout>
            </Route>
            <Route exact path="/list-of-cadth-reports">
              <PageLayout currentPage="listOfCadthReports">
                <ListOfCadthReports />
              </PageLayout>
            </Route>
            <Route exact path="/knowledge-mobilization-tools">
              <PageLayout currentPage="knowledgeMobilizationTools">
                <KnowledgeMobilizationTools />
              </PageLayout>
            </Route>
            <Route path="/guideline/:id">
              <PageLayout className="bg-gray-100" currentPage="guideline">
                <GuidelinePage />
              </PageLayout>
            </Route>
            <Route path="/evidence-report/:id">
              <PageLayout className="bg-gray-100" currentPage="keyMessage">
                <RecommendationPage />
              </PageLayout>
            </Route>
            <Route exact path="/about">
              <PageLayout currentPage="about" className="bg-gray-opacity30">
                <About />
              </PageLayout>
            </Route>
          </>
        </Switch>
      </div>
    </div>
  );
};

function App() {
  const { ready } = useTranslation("", { useSuspense: false });

  // wait until translations are loaded
  return ready ? (
    <Router>
      <AppContent />
    </Router>
  ) : (
    <LargeSpinner />
  );
}

export default App;
