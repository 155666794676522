import React from "react";
import { Trans } from "react-i18next";
import Box from "../common/Box";
import ExternalLink from "../common/ExternalLink";

export default function KnowledgeMobilizationTools() {
  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <Box className="bg-white p-12 border">
        <a
          className="block text-cadth-500 hover:underline"
          rel="noreferrer noopener"
          href="https://www.youtube.com/watch?app=desktop&v=7Tbo_nZMJOo&t=6s"
        >
          CADTH Webinar — Tuberculosis: International and Canadian Perspectives on the “Other”
          Global Pandemic (Video Recording; March 24, 2021)
        </a>
        <p className="mt-4">
          <Trans i18nKey="app.knowledgeMobilizationTools">
            On World TB Day 2021, CADTH hosted an event to discuss tuberculosis (TB) and the efforts
            to eradicate this disease in Canada and internationally. CADTH's work on TB was also
            introduced. The slides used in this event are available{" "}
            <a
              className="text-cadth-500 hover:underline"
              rel="noreferrer noopener"
              href="https://cadth.ca/sites/default/files/events/March24FullDeckREV.pdf"
            >
              here
            </a>
          </Trans>
        </p>
        <Trans i18nKey="app.tuberculosisAPandemicOvershadowed">
          <p className="mt-8">
            <ExternalLink
              className="inline-block text-cadth-500 hover:underline"
              url="https://hospitalnews.com/tuberculosis-a-pandemic-overshadowed-by-another-pandemic/"
            >
              Tuberculosis: A pandemic overshadowed by another pandemic
            </ExternalLink>{" "}
            (Hospital News Article; March 2022)
          </p>
          <div>
            This article provides general commentary on the current status of TB in Canada and
            worldwide and highlights key findings from CADTH's review of the evidence on TB
            vaccines, screening tests for latent TB infection, and treatment options.
          </div>
        </Trans>
        <Trans i18nKey="app.cadthWebinar">
          <div className="mt-8">
            CADTH Webinar — Cultural Competence in Health Care: Delivering Culturally Appropriate
            Care for Tuberculosis in Indigenous Communities (Video Recording; March 24, 2022) On
            World TB Day 2022, CADTH hosted an Indigenous-led discussion on cultural competence in
            the context of tuberculosis (TB) care in Canada. Panelists shared information on the
            history and current situation of TB care among Indigenous populations in Canada. They
            also discussed the importance of providing culturally appropriate TB care in Indigenous
            communities, as well as the need for developing resources to guide such care. This
            webinar provides additional perspectives that complement CADTH's condition level review
            of TB. The recording of this event and key messages from the panelists can be accessed{" "}
            <ExternalLink
              className="inline-block text-cadth-500 hover:underline"
              url="https://www.cadth.ca/events/cultural-competence-health-care-delivering-culturally-appropriate-care-tuberculosis"
            >
              here
            </ExternalLink>
            .
          </div>
        </Trans>
        <Trans i18nKey="app.conditionLevelReviewOnTuberculosis">
          <p className="mt-8">
            <ExternalLink
              className="inline-block text-cadth-500 hover:underline"
              url="https://www.cadth.ca/condition-level-review-tuberculosis-evidence-gaps"
            >
              Condition-Level Review on Tuberculosis: Evidence Gaps
            </ExternalLink>{" "}
            (Summary Tool)
          </p>
          <div>
            The process of conducting this condition-level review uncovered significant evidence
            gaps. In many cases, the research is lacking, dated, and/or limited. Because these gaps
            in the evidence may be important to TB decision-makers, we have captured them in this
            summary document. We also hope that this information will help inform and support future
            research. This summary tool reflects evidence gaps we identified while conducting
            reviews through our Rapid Review service and Reference List service.
          </div>
        </Trans>
      </Box>
    </div>
  );
}
