import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const PageLayout = ({ children, className, currentPage }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const goBack = () => {
    history.length > 2 ? history.goBack() : history.push("/");
  };

  return (
    <div className={`overflow-hidden h-full font-roboto ${className}`}>
      <div className="subpage-header px-4 flex flex-row bg-white shadow font-roboto text-14 font-semibold">
        <button
          className="px-12 py-2 text-white bg-cadth-300 hover:bg-cadth-700 uppercase font-semibold"
          onClick={goBack}
        >
          {t("labels.back")}
        </button>
        {currentPage && (
          <span className="px-12 py-2 text-white bg-cadth-700">{t(`labels.${currentPage}`)}</span>
        )}
      </div>
      <div className="scrollable-page-content px-2 pt-2 sm:pt-5 flex-grow max-w-screen-lg mx-auto mb-2">
        {children}
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentPage: PropTypes.string,
};

PageLayout.defaultProps = {
  className: "",
  currentPage: null,
};

export default PageLayout;
