import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import { isValidLink } from "../../lib/utils";
import Card from "../common/Card";
import CustomTable from "../common/CustomTable";

const Populations = ({ populations }) =>
  _.map(populations, (population) => <div key={population["@id"]}>{population.name}</div>);

const Interventions = ({ interventions }) =>
  _.map(interventions, (intervention) => <div key={intervention["@id"]}>{intervention.name}</div>);

const mapIdsToValues = (ids, values) =>
  _.chain(ids)
    .map((id) => values.find((v) => v["@id"] === id["@id"]))
    .compact()
    .value();

const getAdditionalRows = (question, t) =>
  _.chain(["evidenceProfile", "etd", "evidenceSynthesis"])
    .map((key) => {
      if (!_.isEmpty(question[`${key}Reference`])) {
        return {
          label: t(`labels.${key}`),
          value: isValidLink(question[`${key}Reference`]) ? (
            <a
              className="external-link"
              href={question[`${key}Reference`]}
              target="_blank"
              rel="noreferrer noopener"
            >
              {!_.isEmpty(question[`${key}Pages`])
                ? t("labels.see_pages", { pages: question[`${key}Pages`] })
                : question[`${key}Reference`]}
            </a>
          ) : (
            question[`${key}Reference`]
          ),
        };
      }
      return null;
    })
    .compact()
    .value();

const ExternalLink = ({ label, url }) => (
  <a
    className="py-0 px-0 md:px-2 block break-all"
    href={url}
    rel="noopener noreferrer"
    target="_blank"
  >
    {label}
  </a>
);

const EvidenceTableUrls = ({ evidenceTableUrl }) => {
  return (
    <div>
      {_.map(evidenceTableUrl, (url, idx) => (
        <ExternalLink key={idx} label={url} url={url} />
      ))}
    </div>
  );
};

const QuestionData = ({
  commonStrengthAndCertainty,
  question,
  interventions,
  populations,
  evidenceTableUrl,
}) => {
  const { t } = useTranslation();
  const questionPopulations = mapIdsToValues(question.population, populations);
  const questionInterventions = mapIdsToValues(question.intervention, interventions);

  const additionalRows = getAdditionalRows(question, t);
  return (
    <Card className={`question-data my-5 ${!commonStrengthAndCertainty ? "m-5 px-0" : ""} border`}>
      <div className="flex flex-row">
        <CustomTable
          className="flex-grow"
          valueClassName="sm:px-0 sm:py-0"
          rows={[
            {
              label: t("labels.population"),
              value: (
                <div className="py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3">
                  <Populations populations={questionPopulations} />
                </div>
              ),
            },
            {
              label: t("labels.intervention"),
              value: (
                <div className="py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3">
                  <Interventions interventions={questionInterventions} />
                </div>
              ),
            },
            !_.isEmpty(evidenceTableUrl)
              ? {
                  label: t("labels.evidenceTables"),
                  value: (
                    <div className="py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3">
                      <EvidenceTableUrls evidenceTableUrl={evidenceTableUrl} />
                    </div>
                  ),
                }
              : null,
          ]}
        />
      </div>
      {!_.isEmpty(additionalRows) && (
        <div className="flex flex-row mt-8">
          <CustomTable className="flex-grow" rows={additionalRows} />
        </div>
      )}
    </Card>
  );
};

QuestionData.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  populations: PropTypes.arrayOf(populationShape).isRequired,
  interventions: PropTypes.arrayOf(interventionShape).isRequired,
  question: questionShape.isRequired,
};

QuestionData.defaultProps = {
  commonStrengthAndCertainty: true,
};

export default QuestionData;
